.categories-list {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 10px;
    margin: 0 0 1rem;
    max-width: 100%;
    padding: 0;

    .categories-list-item {
        list-style-type: none;
        margin: 0 5px 5px;
        margin: 0 0.5rem 0.5rem;

        a {
            background: var(--background-color-dark);
            border-radius: var(--border-radius-small);
            color: var(--text-color-light);
            display: block;
            font-size: 12px;
            font-size: 1.2rem;
            font-weight: 700;
            padding: 5px 15px;
            padding: 0.5rem 1.5rem;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color var(--linear), color var(--linear);

            &:hover,
            &:focus,
            &:active {
                background: var(--background-color-medium);
                color: var(--text-color-dark);
            }
        }
    }
}