.author-box {
    align-items: center;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-style) var(--box-shadow-color), var(--box-shadow-style-alt) var(--box-shadow-color-alt);
    display: flex;
    flex-flow: row wrap;
    margin: 60px 20px;
    margin: 6rem 2rem;
    padding: 5px;
    padding: 0.5rem;

    @media (--content-query) {
        flex: 0 1 auto;
        margin: 60px 0;
        margin: 6rem 0;
    }

    @media (--menu-query) {
        margin: 60px 0;
        margin: 6rem 0;
    }

    .author-image-container {
        border-radius: var(--border-radius);
        flex: 0 1 100%;
        padding: 5px;
        padding: 0.5rem;

        @media (--content-query) {
            flex: 0 1 100px;
        }

        a {
            display: block;

            img {
                border-radius: var(--border-radius);
                display: block;
                height: auto;
                max-width: 100%;
            }
        }
    }

    .author-description {
        flex: 0 1 100%;
        padding: 10px;
        padding: 1rem;

        @media (--content-query) {
            flex: 0 1 calc(100% - 100px);
        }

        .author-name-link {
            color: var(--link-color);
            display: block;
            font-size: 24px;
            font-size: 2.4rem;
            font-weight: 700;
            margin-bottom: 10px;
            margin-bottom: 1rem;
            text-decoration: none;
            transition: color var(--linear);

            &:hover,
            &:focus,
            &:active {
                color: var(--link-color-hover);
            }
        }

        .author-bio {
            font-size: 18px;
            font-size: 1.8rem;
            line-height: 1.5;
        }
    }
}