.alignfull {
    flex: 0 1 100%;
    max-width: 100%;
}

.alignwide {
    flex: 0 1 100%;
    margin: auto;
    max-width: 960px;
    max-width: 96rem;
}

.alignfull,
.alignwide {
    .wp-block-group__inner-container {
        margin: 0 auto;
        max-width: 1440px;

        > * {
            max-width: 100%;
        }

        p,
        ul,
        ol {
            margin-left: 0;
        }
    }
}

.aligncenter {
    clear: both;
    margin-left: auto;
    margin-right: auto;
}

.alignleft {
    clear: left;
    float: left;
    margin-right: 20px;
    margin-right: 2rem;
}

.alignnone {
    clear: both;
    margin: 0;
}

.alignright {
    clear: right;
    float: right;
    margin-left: 20px;
    margin-left: 2rem;
}

.sticky {
    display: block;
}

.wp-caption {
    margin: 20px 0;
    margin: 2rem 0;

    &.aligncenter {
        margin-left: auto;
        margin-right: auto;
    }

    &.alignleft {
        margin-right: 20px;
        margin-right: 2rem;
    }

    &.alignright {
        margin-left: 20px;
        margin-left: 2rem;
    }
}

.wp-block-image {
    .alignleft {
        float: none;
        margin-left: auto;
        margin-right: auto;

        @media (--content-query) {
            float: left;
            margin-right: 20px;
            margin-right: 2rem;
        }
    }

    .alignright {
        float: none;
        margin-left: auto;
        margin-right: auto;

        @media (--content-query) {
            float: right;
            margin-left: 20px;
            margin-left: 2rem;
        }
    }
}

blockquote {
    display: flex;
    flex-flow: row wrap;
    padding-left: 40px;
    padding-left: 4rem;
    position: relative;

    &:before {
        content: "\f10d";
        font-family: "Font Awesome 5 Free";
        font-size: 30px;
        font-size: 3rem;
        font-weight: 900;
        left: 0;
        margin: 0 10px 10px 0;
        margin: 0 1rem 1rem 0;
        position: absolute;
        top: 10px;
    }

    p,
    cite {
        flex: 0 1 100%;
    }

    p:first-of-type {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 10px;
        margin-bottom: 1rem;
    }

    cite {
        border-top: var(--border);
        padding-top: 10px;
        padding-top: 1rem;
        text-align: right;
    }
}