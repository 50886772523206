.site-header {
    .search-container {
        position: relative;

        .search-toggle {
            span {
                width: 14px;
            }
        }

        .search-form {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 50px;
            top: 0;
            transition: opacity var(--linear), width var(--linear);
            width: 0;
            z-index: 100;

            label {
                display: block;
            }

            input[type=search] {
                appearance: none;
                border: var(--border);
                border-bottom-left-radius: var(--border-radius);
                border-top-left-radius: var(--border-radius);
                display: none;
                font-size: 14px;
                font-size: 1.4rem;
                padding: 10px 10px;
                padding: 1rem 1rem;
            }

            input[type=submit] {
                background: var(--search-submit-color);
                border: var(--border);
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                color: var(--search-submit-text-color);
                display: none;
                transition: border-color var(--linear);

                &:hover,
                &:focus,
                &:active {
                    background: var(--search-submit-color-hover);
                    border-color: var(--button-border-color-hover);
                    color: var(--search-submit-text-color-hover);
                }
            }
        }

        &.open .search-form {
            opacity: 1;
            pointer-events: all;
            width: 300px;

            input {
                display: inline-block;
            }
        }
    }
}