.carousel {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    max-width: 100vw; /* To prevent overflow on smaller screens */
    padding: 20px;
    
    .slides {
        align-items: stretch;
        display: flex;
        flex: 0 1 100%;
        height: auto;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 20px;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 100%;
            height: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: var(--carousel-scrollbar-track-color);
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--carousel-scrollbar-thumb-color);
        }
    }

    .slide {
        align-items: center;
        background: var(--slide-background-color);
        border: var(--border-heavy);
        border-radius: var(--border-radius);
        color: var(--slide-text-color);
        display: flex;
        flex: 0 0 100%;
        flex-flow: row wrap;
        justify-content: center;
        margin-right: 20px;
        margin-right: 2rem;
        padding: 20px;
        padding: 2rem;

        &:last-of-type {
            margin-right: 0;
        }

        .slide-image-container,
        .slide-content {
            flex: 0 1 100%;
            width: 100%;

            @media (--content-query) {
                flex: 0 1 50%;
                width: 50%;
            }
        }

        .slide-image-container {
            border-radius: var(--border-radius);

            img {
                border-radius: var(--border-radius);
                display: block;
                height: auto;
                margin: 0 auto;
                max-width: 100%;
                width: 100%;
            }
        }

        .slide-content {
            padding: 10px 0;
            padding: 1rem 0;

            @media (--content-query) {
                padding: 20px 40px;
                padding: 2rem 4rem;
            }
        }
    }
}

.carousel-navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 0 20px;
    margin: 0 0 2rem;
    padding: 0;

    .carousel-navigation-button-container {
        list-style-type: none;
        margin: 0 10px;
        margin: 0 1rem;

        .carousel-navigation-button {
            align-items: center;
            background: var(--carousel-navigation-button-color);
            border-radius: var(--border-radius-small);
            display: flex;
            height: 20px;
            justify-content: center;
            transition: background-color var(--linear);
            width: 80px;

            &.current {
                background: var(--carousel-navigation-button-color-current);
            }

            &:hover,
            &:focus,
            &:active {
                background: var(--carousel-navigation-button-color-hover);
            }
        }
    }
}