.header-navigation {
    display: flex;
    flex: 0 1 100%;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-top: 10px;
    margin-top: 1rem;

    @media (--menu-button-query) {
        flex: 0 1 calc(100% - 250px);
        margin-top: 0;
    }

    .menu-toggle {
        background: var(--menu-toggle-color);
        color: var(--menu-toggle-text-color);
        display: block;
        flex: 0 1 100%;

        @media (--menu-button-query) {
            display: inline-block;
            flex-basis: auto;
        }

        @media (--menu-query) {
            display: none;
        }
    }

    .main-menu-container {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        position: relative;

        .menu {
            align-items: center;
            display: none;
            flex-flow: row wrap;
            justify-content: flex-end;
            margin: 0 20px 0 0;
            margin: 0 2rem 0 0;
            padding: 0;

            @media (--menu-query) {
                display: flex;
            }
    
            .menu-item {
                align-items: center;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                list-style-type: none;
                margin: 0 5px;
                margin: 0 0.5rem;
                padding: 0;
                position: relative;
    
                a,
                > span {
                    color: var(--nav-item-color);
                    display: block;
                    font-family: var(--font-special);
                    font-size: 16px;
                    font-size: 1.6rem;
                    position: relative;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: color var(--linear);

                    &:after {
                        background: var(--nav-item-color-hover);
                        border-radius: var(--border-radius-tiny);
                        bottom: -7px;
                        content: '';
                        height: 5px;
                        left: 0;
                        position: absolute;
                        transform: scale3d(0, 1, 1);
                        transition: transform var(--cubic);
                        width: 100%;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--nav-item-color-hover);

                        &:after {
                            transform: scale3d(1, 1, 1);
                        }
                    }
                }

                &.current-menu-item {
                    a,
                    > span {
                        color: var(--nav-item-color-hover);

                        &:after {
                            transform: scale3d(1, 1, 1);
                        }
                    }
                }

                &.menu-item-has-children {
                    a, 
                    > span {
                        padding-right: 5px;
                        padding-right: 0.5rem;
                    }
                }

                .sub-menu-activator {
                    padding: 3px 5px;
                    padding: 0.3rem 0.5rem;
                
                    span:not(.screen-reader-text) {
                        display: block;
                    }
                }
                
                .sub-menu {
                    background: var(--sub-menu-color);
                    display: none;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    top: 100%;
                    width: 270px;
                    z-index: 100;
                
                    .menu-item {
                        margin: 0;
                        text-align: left;

                        &:hover {
                            background: var(--sub-menu-item-color-hover);

                            > a,
                            > span {
                                color: var(--sub-menu-item-text-color-hover);
                            }
                        }
                    
                        a,
                        > span {
                            color: var(--sub-menu-text-color);
                            flex: 0 1 100%;
                            padding: 0 5px;
                            padding: 0 0.5rem;
                
                            &:hover,
                            &:focus,
                            &:active {
                                background: var(--sub-menu-item-color-hover);
                                color: var(--sub-menu-item-text-color-hover);
                            }
                
                            &:after {
                                display: none;
                            }
                        }

                        &.menu-item-has-children {
                            > a,
                            > span {
                                flex: 0 1 calc(100% - 20px);
                            }
                        }
                    }
                }

                &.open > .sub-menu {
                    display: block;
                }

                &.open:not(.menu-level-0) > .sub-menu {
                    right: -270px;
                    top: 0;
                }
            }
        }
    }
}