.off-canvas-container {
    .search-container {
        display: block;

        @media (--menu-query) {
            display: none;
        }

        .search-form {
            align-items: center;
            display: flex;

            label {
                display: block;
            }

            input[type=search] {
                appearance: none;
                border: var(--border);
                border-bottom-left-radius: var(--border-radius);
                border-top-left-radius: var(--border-radius);
                font-size: 14px;
                font-size: 1.4rem;
                padding: 10px 10px;
                padding: 1rem 1rem;
                width: 195px;
            }

            input[type=submit] {
                background: var(--button-color-alt);
                border: var(--border);
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                color: var(--button-text-color-alt);
                transition: border-color var(--linear);

                &:hover,
                &:focus,
                &:active {
                    background: var(--button-color-alt-hover);
                    border-color: var(--button-border-color-hover);
                    color: var(--button-text-color-alt-hover);
                }
            }
        }
    }
}