.cookie-bar {
    align-items: center;
    background: var(--cookie-bar-color);
    bottom: 0;
    color: var(--cookie-bar-text-color);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    left: 0;
    padding: 20px;
    padding: 2rem;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 1000;

    &.hide {
        display: none;
    }

    .cookie-bar-text {
        color: var(--cookie-bar-text-color);
        flex: 0 1 100%;
        line-height: 1;
        margin: 0 20px 20px;
        margin: 0 2rem 2rem;

        @media (--content-query) {
            flex: 0 1 auto;
            margin-bottom: 0;
        }
    }

    button,
    .button {
        &:first-of-type {
            margin-right: 20px;
            margin-right: 2rem;
        }
    }
}