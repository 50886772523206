.entry-content {
    background: var(--white);
    padding: 20px 20px 0;
    padding: 2rem 2rem 0;
    width: 100%;

    .home & {
        padding: 0;
    }

    .single-post & {
        max-width: 100%;
    }

    .not-found & {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        padding: 20px;
        padding: 2rem;
    }

    .page & {
        padding: 0;

        @media (--menu-query) {
            border-radius: var(--border-radius);
        }
    }

    .bbpress & {
        padding: 20px;
        padding: 2rem;

        @media (--menu-query) {
            border-radius: var(--border-radius);
        }
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    > *:not(.wp-block-cover):not(.alignwide):not(.alignfull):not(.bbpress-wrapper) {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--measure);

        @media (--menu-query) {
            max-width: 720px;
            max-width: 72rem;
        }
    }

    .blog & > *:not(.wp-block-cover):not(.alignwide):not(.alignfull),
    .single-post & > *:not(.wp-block-cover):not(.alignwide):not(.alignfull),
    .single-wyvern_faqs & > *:not(.wp-block-cover):not(.alignwide):not(.alignfull),
    .search & > *:not(.wp-block-cover):not(.alignwide):not(.alignfull) {
        max-width: calc(100vw - 40px);
    }
}