.gform_wrapper {
    .ginput_container {
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
        select,
        textarea {
            border: var(--border);
            border-radius: var(--border-radius);
            padding: 10px;
            padding: 1rem;
        }
    }
}