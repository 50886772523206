.social-action-buttons {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;

    .social-action-button {
        flex: 0 1 100%;
        list-style-type: none;
        margin: 0 0 10px;
        margin: 0 0 1rem;

        @media (--menu-button-query) {
            flex: 0 1 auto;
            margin: 0 5px 10px;
            margin: 0 0.5rem 1rem;
        }

        @media (--content-query) {
            margin-bottom: 0;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        .button {
            display: block;
        }
    }
}