.progress-bar {
    display: block;
    height: 10px;
    background: var(--progress-bar-color);
    left: 0;
    position: fixed;
    top: 0;
    width: auto;
    z-index: 1000;

    .admin-bar & {
        top: 32px;
    }

    .progress-bar-icon {
        display: block;
        height: auto;
        top: -7px;
        position: absolute;
        right: -20px;
        width: 30px;
    }
}