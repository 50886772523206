.off-canvas-container {
    .menu-toggle {
        display: block;
        margin: 10px 0;
        margin: 1rem 0;
        width: 100%;
    }

    .menu {
        margin: 20px 0 0;
        margin: 2rem 0 0;
        padding: 0;

        .menu-item {
            align-items: center;
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 5px;
            margin-bottom: 0.5rem;

            > a,
            > span {
                color: var(--off-canvas-nav-item-color);
                display: block;
                flex: 0 1 100%;
                font-family: var(--font-special);
                font-size: 16px;
                font-size: 1.6rem;
                text-decoration: none;
                text-transform: uppercase;
                transition: color var(--linear);

                &:hover,
                &:focus,
                &:active {
                    color: var(--off-canvas-nav-item-color-hover);
                }
            }

            &.menu-item-has-children {
                align-items: center;
                display: flex;
                flex-flow: row wrap;

                > a,
                > span {
                    flex: 0 1 calc(100% - 30px);
                }
            }

            .sub-menu-activator {
                background: var(--off-canvas-sub-menu-button-color);
                color: var(--off-canvas-sub-menu-button-text-color);
                padding: 10px;
                padding: 1rem;
            
                span:not(.screen-reader-text) {
                    display: block;
                }
            }

            .sub-menu {
                display: none;
                flex: 0 1 100%;
                padding: 10px 0 0 20px;
                padding: 1rem 0 0 2rem;
            }

            &.open > .sub-menu {
                display: block;
            }
        }
    }
}