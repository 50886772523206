/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
.wrapper {
    margin: 0 auto;
    max-width: 1440px;
}

.page-content {
    display: flex;
    flex-flow: row wrap;

    .blog &,
    .archive &,
    .search & {
        padding: 20px 0;
        padding: 2rem 0;

        @media (--content-query) {
            padding: 60px 0;
            padding: 6rem 0;
        }
    }

    .single & {
        padding: 0 0 20px;
        padding: 0 0 2rem;

        @media (--content-query) {
            padding: 0 20px 60px;
            padding: 0 2rem 6rem;
        }
    }

    .bbpress & {
        padding: 0;
    }
}

.main {
    flex: 0 1 100%;

    @media (--menu-query) {
        flex: 0 1 72%;
        margin-right: 3%;

        .home &,
        .page &,
        .bbpress & {
            flex: 0 1 100%;
            margin-right: 0;
        }

        .home & {
            align-items: center;
            display: flex;
            flex-flow: row wrap;

            .home-block {
                flex: 0 1 100%;
            }
        }
    }
}

.sidebar {
    flex: 0 1 100%;

    @media (--menu-query) {
        flex: 0 1 25%;
        margin: 0 auto;
        max-width: 300px;
    }
}

.posts {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 20px 0;
    padding: 2rem 0;

    @media (--menu-query) {
        padding: 0;
    }
}