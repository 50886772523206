.sidebar {
    padding: 20px;
    padding: 2rem;

    @media (--content-query) {
        padding: 0;
    }

    .widget {
        margin-bottom: 20px;
        margin-bottom: 2rem;
    }

    .widget-title {
        border-bottom: var(--border);
        margin-top: 0;
        padding-bottom: 20px;
        padding-bottom: 2rem;
    }
}