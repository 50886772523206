/*--------------------------------------------------------------
# Theming - Variables specific to the theme, using colors 
# defined in the main Variables file as well as here
--------------------------------------------------------------*/
:root {
	/* Theme Colors Go Here */

	/* Custom Fonts */
	--font-plain: sans-serif;
	--font-special: serif;

	/* Defaults */
	--background-color-dark: var(--black);
	--background-color-medium: var(--grey);
	--background-color-light: var(--white);
	--border: 1px solid var(--black);
	--border-radius: 10px;
	--border-radius-inner: 6px;
	--border-radius-small: 5px;
	--border-radius-tiny: 3px;
	--border: 1px solid var(--black);
	--border-heavy: 4px solid var(--black);
	--border-alt: 1px solid var(--grey);
	--border-heavy-alt: 4px solid var(--grey);
	--border-light: 1px solid var(--light-grey);
	--border-dashed: 2px dashed var(--black);
	--border-dashed-alt: 2px dashed var(--white);
	--border-dashed-light: 2px dashed var(--light-grey);
	--box-shadow-style: 0px 1px 2px;
	--box-shadow-color: rgba(0, 0, 0, 0.25);
	--box-shadow-style-alt: 0px 2px 4px;
	--box-shadow-color-alt: rgba(0, 0, 0, 0.25);
	--caption-color: var(--black);
	--caption-text-color: var(--white);
	--selection-color: var(--black);
	--selection-text-color: var(--white);
	--text-color-dark: var(--black);
	--text-color-medium: var(--grey);
	--text-color-light: var(--white);
	--text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
	--video-icon-color: var(--white);

	/* Links */
	--link-color: var(--dark-grey);
	--link-color-hover: var(--black);

    /* Button Colors */
	--button-color: var(--black);
	--button-text-color: var(--white);
	--button-color-alt: var(--grey);
	--button-text-color-alt: var(--black);
	--button-color-disabled: var(--grey);
	--button-text-color-disabled: var(--black);
	--search-submit-color: var(--grey);
	--search-submit-text-color: var(--black);

    /* Button Hovers */
	--button-border-color-hover: var(--grey);
	--button-color-hover: var(--grey);
	--button-text-color-hover: var(--black);
	--button-color-alt-hover: var(--black);
	--button-text-color-alt-hover: var(--white);
	--search-submit-color-hover: var(--grey);
	--search-submit-text-color-hover: var(--white);

	/* Menu Colors */
	--nav-item-color: var(--white);
	--nav-item-color-hover: var(--grey);
	--menu-toggle-color: var(--grey);
	--menu-toggle-text-color: var(--black);
	--off-canvas-color: var(--black);
	--off-canvas-border: 5px solid var(--grey);
	--off-canvas-nav-item-color: var(--white);
	--off-canvas-nav-item-color-hover: var(--grey);
	--off-canvas-sub-menu-button-color: var(--grey);
	--off-canvas-sub-menu-button-text-color: var(--black);
	--sub-menu-color: var(--grey);
	--sub-menu-text-color: var(--black);
	--sub-menu-item-color-hover: var(--black);
	--sub-menu-item-text-color-hover: var(--white);

	/* Header Colors */
	--site-header-background-color: var(--black);
	--site-header-text-color: var(--white);
	--footer-nav-item-color: var(--white);
	--footer-nav-item-color-hover: var(--grey);

	/* Footer Colors */
	--site-footer-background-color: var(--black);
	--site-footer-text-color: var(--white);
	--copyright-color: var(--black);
	--copyright-text-color: var(--white);

	/* Cookie Bar */
	--cookie-bar-color: var(--grey);
	--cookie-bar-text-color: var(--black);

	/* Page Header */
	--page-header-color: var(--black);
	--page-header-text-color: var(--white);

	/* Post Card Block & Cards */
	--post-card-block-background-color: var(--black);
	--post-card-background-color: var(--white);
	--post-card-background-color-sticky: var(--grey);
	--post-card-border-color-sticky: var(--white);

	/* Pagination */
	--pagination-color: var(--grey);
	--pagination-color-hover: var(--black);
	--pagination-text-color: var(--white);
	--pagination-text-color-hover: var(--white);

	/* Affiliate Notice */
	--affiliate-notice-color: var(--black);
	--affiliate-notice-text-color: var(--white);
	--affiliate-notice-link-color: var(--grey);
	--affiliate-notice-link-color-hover: var(--grey);

	/* Post Navigation */
	--post-navigation-color: var(--black);
	--post-navigation-text-color: var(--white);
	--post-navigation-color-hover: var(--grey);
	--post-navigation-text-color-hover: var(--white);

	/* Progress Bar */
	--progress-bar-color: linear-gradient(90deg, var(--black) 0%, var(--grey) 100%);

	/* bbPress */
	--bbpress-header-color: var(--black);
	--bbpress-header-text-color: var(--white);

	/* Built-In Blocks */
	/* Featured Posts Carousel */
	--slide-background-color: var(--white);
	--slide-text-color: var(--black);
	--carousel-navigation-button-color: var(--black);
	--carousel-navigation-button-color-current: var(--grey);
	--carousel-navigation-button-color-hover: var(--grey);
	--carousel-scrollbar-track-color: var(--grey);
	--carousel-scrollbar-thumb-color: var(--black);

	/* Accordion */
	--accordion-header-color: var(--grey);
	--accordion-header-text-color: var(--black);
	--accordion-header-color-hover: var(--black);
	--accordion-header-text-color-hover: var(--white);

	/* Newsletter */
	--newsletter-background: var(--grey);
	--newsletter-text-color: var(--black);
}