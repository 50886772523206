.post-card {
    background: var(--post-card-background-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-style) var(--box-shadow-color), var(--box-shadow-style-alt) var(--box-shadow-color-alt);
    display: flex;
    flex-flow: column wrap;
    margin: 0 20px 40px;
    margin: 0 2rem 4rem;
    flex: 0 1 100%;

    &.sticky {
        background: var(--post-card-background-color-sticky);
        border: var(--border-heavy);
        border-color: var(--post-card-border-color-sticky);
    }

    @media (--menu-button-query) {
        flex: 0 1 calc(50% - 40px);
    }

    @media (--menu-query) {
        flex: 0 1 calc(33% - 40px);
    }

    .post-card-image-container {
        display: block;
        margin: 0;
        max-width: 100%;
        position: relative;

        .post-card-image {
            border-radius: var(--border-radius);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            display: block;
            height: auto;
            margin: 0 auto;
            max-width: 100%;
        }

        .video-icon {
            color: var(--video-icon-color);
            font-size: 40px;
            font-size: 4rem;
            left: 0;
            padding: 10px;
            pointer-events: none;
            position: absolute;
            top: 0;
            z-index: 2;
        }
    }

    .post-card-content {
        display: flex;
        flex: 1 0 auto;
        flex-flow: column wrap;
        padding: 15px;
        padding: 1.5rem;

        .post-excerpt,
        .post-excerpt p {
            margin: 0;
        }

        .read-more {
            align-items: flex-end;
            display: flex;
            flex: 1 0 auto;
            margin-top: 20px;
            margin-top: 2rem;

            .read-more-button {
                width: 100%;
                text-align: center;
            }

            .read-more-text {
                display: inline-block;
                margin-right: 5px;
                margin-right: 0.5rem;
            }

            .icon {
                display: inline-block;
                margin-bottom: 3px;
                margin-bottom: 0.3rem;
            }
        }
    }

    .archive &,
    .search &,
    .blog & {
        display: flex;
        flex: 0 1 100%;
        flex-flow: row wrap;
        padding: 10px;
        padding: 1rem;

        .post-card-image-container {
            flex: 0 1 100%;
            margin-bottom: 20px;
            margin-bottom: 2rem;
            padding: 0;

            @media (--content-query) {
                flex: 0 1 40%;
                margin: 0 20px 0 0;
                margin: 0 2rem 0 0;
            }

            .post-card-image {
                border-radius: var(--border-radius);
            }
        }

        .post-card-content {
            flex: 0 1 100%;
            padding: 0;

            @media (--content-query) {
                flex: 0 1 calc(60% - 20px);
            }
        }
    }
}