#bbpress-forums {
    align-items: center;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    margin-bottom: 0;

    .single-forum & {
        flex-flow: row wrap;
    }

    .bbp-search-form {
        flex: 0 1 100%;
        margin-bottom: 0;

        @media (--menu-button-query) {
            flex: 0 0 300px;
        }

        #bbp-search-form {
            div {
                align-items: center;
                display: flex;
                flex-flow: row wrap;

                #bbp_search {
                    border: var(--border-heavy);
                    border-radius: var(--border-radius);
                    flex: 0 1 calc(100% - 104px);
                    font-size: 16px;
                    font-size: 1.6rem;
                    margin-right: 4px;
                    margin-right: 0.4rem;
                    max-width: 180px;
                    padding: 5px;
                    padding: 0.5rem;
                    width: auto;
                }

                input {
                    font-size: 14px;
                    font-size: 1.4rem;
                    padding: 10px 20px;
                    padding: 1rem 2rem;
                }
            }
        }
    }

    div.bbp-breadcrumb {
        flex: 0 1 100%;
        margin: 0;

        @media (--menu-button-query) {
            flex: 0 1 calc(100% - 300px);
        }

        p {
            margin: 0;
        }
    }

    div.bbp-topic-tags {
        flex: 0 0 100%;
    }

    ul.bbp-forums,
    ul.bbp-replies,
    ul.bbp-topics {
        border: var(--border);
        flex: 0 1 100%;
        margin: 10px 0 0;
        margin: 1rem 0 0;

        li.bbp-header,
        li.bbp-footer {
            background: var(--bbpress-header-color);
            border-top: none;
            color: var(--bbpress-header-text-color);
        }
    }

    ul.bbp-topics {
        margin-top: 0;
    }

    #favorite-toggle,
    #subscription-toggle {
        color: transparent;
        float: none;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1;
    }
}

div.bbp-template-notice,
.bbp-topic-form,
.bbp-reply-form {
    flex: 0 1 100%;
}

div.bbp-template-notice {
    margin-top: 20px;
}

.topic {
    #bbpress-forums {
        flex-flow: row wrap;
    }
}