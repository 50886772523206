.read-more {
    color: var(--link-color);
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    transition: color var(--linear);

    .read-more-text {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--link-color-hover);
    }
}