.accordion-container {
    .accordion-trigger {
        align-items: center;
        background: var(--accordion-header-color);
        border-radius: var(--border-radius-inner);
        color: var(--accordion-header-text-color);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        font-size: inherit;
        padding: 10px 20px;
        padding: 1rem 2rem;
        text-align: left;
        transition: border-width var(--linear);
        width: 100%;

        &:hover,
        &:focus,
        &:active {
            background-color: var(--accordion-header-color-hover);
            color: var(--accordion-header-text-color-hover);
        }

        &[aria-expanded='true'] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .accordion-content {
        padding: 20px;
        padding: 2rem;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}