.post {
    border-radius: var(--border-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: var(--box-shadow-style) var(--box-shadow-color), var(--box-shadow-style-alt) var(--box-shadow-color-alt);

    @media (--content-query) {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
}

.post-title {
    margin: 0 0 10px;
    margin: 0 0 1rem;

    .post-card & {
        font-size: calc(var(--s2) * 0.85);
    }

    a {
        color: var(--text-color-dark);
        text-decoration: none;
        transition: color var(--linear);

        &:hover,
        &:focus,
        &:active {
            color: var(--text-color-medium);
        }
    }
}

.post-excerpt {
    line-height: 1.75;
    margin-top: 0;
}