.entry-header {
    padding: 20px 20px 0;
    padding: 2rem 2rem 0;

    @media (--menu-query) {
        padding: 0;
    }

    .not-found & {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }

    &.has-image {
        padding: 0;

        .entry-header-image {
            border-radius: 0;

            @media (--content-query) {
                border-top-left-radius: var(--border-radius);
                border-top-right-radius: var(--border-radius);
            }
        }
    }

    &.has-background-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .entry-header-content {
        background: var(--white);
        padding: 20px 20px 0;
        padding: 2rem 2rem 0;

        @media (--menu-query) {
            padding: 20px 20px 0;
            padding: 2rem 2rem 0;
        }

        .not-found & {
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
        }
    }

    .entry-title {
        margin: 0 0 20px;
        margin: 0 0 2rem;

        .not-found & {
            margin-bottom: 0;
        }

        .page & {
            margin: 0;
            padding: 120px 0;
            padding: 12rem 0;

            @media (--menu-query) {
                padding: 200px 0;
                padding: 20rem 0;
            }
        }
    }

    .featured-image-container {
        position: relative;

        .featured-video-container {
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: bottom var(--linear);
            width: 100%;
            z-index: 2;

            @media (--content-query) {
                border-top-left-radius: var(--border-radius);
                border-top-right-radius: var(--border-radius);
            }

            iframe {
                height: 100%;
                width: 100%;

                @media (--content-query) {
                    border-top-left-radius: var(--border-radius);
                    border-top-right-radius: var(--border-radius);
                }
            }
        }

        &.floating-video {
            @media (--menu-query) {
                position: static;

                .featured-video-container {
                    border-radius: var(--border-radius);
                    bottom: 10px;
                    height: 253px;
                    left: auto;
                    position: fixed;
                    right: 10px;
                    top: auto;
                    width: 450px;
                    z-index: 1000;

                    iframe {
                        border-radius: var(--border-radius);
                    }
                }
            }
        }
    }
}