.breadcrumbs {
    align-items: center;
    display: flex;
    flex: 0 1 100%;
    flex-flow: row wrap;
    margin: 0;
    padding: 20px 10px;
    padding: 2rem 1rem;

    .breadcrumb {
        flex: 0 1 100%;
        font-size: 18px;
        font-size: 1.8rem;
        list-style-type: none;
        padding: 5px 0;
        padding: 0.5rem 0;

        @media (--content-query) {
            flex: 0 1 auto;
            padding: 0;
        }

        &.current {
            font-weight: 700;
        }
    }

    .separator {
        font-size: 14px;
        font-size: 1.4rem;
        padding: 0 10px;
        padding: 0 1rem;
    }
}