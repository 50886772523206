.search-form {
    align-items: center;
    display: flex;

    label {
        display: block;
        width: calc(100% - 102px);
    }

    input[type=search] {
        appearance: none;
        border: var(--border);
        border-bottom-left-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
        font-size: 14px;
        font-size: 1.4rem;
        padding: 10px;
        padding: 1rem;
        width: 100%;
    }

    input[type=submit] {
        border: var(--border);
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        transition: border-color var(--linear);

        &:hover,
        &:focus,
        &:active {
            border-color: var(--button-border-color-hover);
        }
    }
}