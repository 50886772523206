.sidebar {
    .widget {
        .post-card {
            @media (--menu-query) {
                margin: 0 0 40px;
                margin: 0 0 4rem;
                flex: 0 1 100%;
            }
        }
    }
}