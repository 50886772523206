.faq {
    border: var(--border-heavy);
    border-radius: var(--border-radius);
    margin-bottom: 20px;
    margin-bottom: 2rem;

    .faq-header {
        margin: 0;
    }

    .accordion-panel {
        border-top: var(--border-heavy);

        &[hidden] {
            border-top: none;
        }
    }
}