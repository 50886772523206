.wp-block-wyvern-plugin-posts-with-filters {
    background: var(--post-card-block-background-color);

    .posts-category-filters {
        align-items: center;
        display: block;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0;
        padding: 40px 0 20px;
        padding: 4rem 0 2rem;
        text-align: center;

        @media (--menu-query) {
            display: flex;
        }

        .posts-category-filter {
            list-style-type: none;
            margin: 0 10px;
            margin: 0 1rem;

            a {
                color: var(--nav-item-color);
                display: block;
                font-family: var(--font-special);
                font-size: 18px;
                font-size: 1.8rem;
                position: relative;
                text-decoration: none;
                text-transform: uppercase;
                transition: color var(--linear);

                &:after {
                    background: var(--nav-item-color-hover);
                    border-radius: var(--border-radius-tiny);
                    bottom: 0;
                    content: '';
                    display: none;
                    height: 5px;
                    left: 0;
                    position: absolute;
                    transform: scale3d(0, 1, 1);
                    transition: transform var(--cubic);
                    width: 100%;

                    @media (--menu-query) {
                        display: block;
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    color: var(--nav-item-color-hover);

                    &:after {
                        transform: scale3d(1, 1, 1);
                    }
                }
            }

            &.current {
                a {
                    color: var(--nav-item-color-hover);

                    &:after {
                        transform: scale3d(1, 1, 1);
                    }
                }
            }
        }
    }

    .posts-container {
        padding: 20px;
        padding: 2rem;
        
        @media (--content-query) {
            padding: 40px;
            padding: 4rem;
        }

        .wrapper {
            align-items: stretch;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        .button-container {
            flex: 0 1 100%;
            text-align: center;
        }

        .post-card:nth-of-type(4) {
            @media (--menu-query) {
                flex: 0 1 100%;
                flex-flow: row wrap;
                padding: 10px;
                padding: 1rem;
            }

            .post-card-image-container,
            .post-card-content {
                @media (--menu-query) {
                    flex: 0 1 50%;
                    width: 50%;
                }
            }
    
            .post-card-image-container {
                @media (--menu-query) {
                    margin-bottom: 0;
                    padding-right: 20px;
                    padding-right: 2rem;

                    img {
                        border-radius: var(--border-radius);
                        width: 100%;
                    }
                }
            }
    
            .post-card-content {
                .post-title {
                    font-size: var(--s2);
                    margin-bottom: 10px;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .post-card:nth-of-type(8) {
        @media (--menu-query) {
            flex: 0 1 100%;
            flex-flow: row-reverse wrap-reverse;
            padding: 10px;
            padding: 1rem;
        }

        .post-card-image-container,
        .post-card-content {
            @media (--menu-query) {
                flex: 0 1 50%;
                width: 50%;
            }
        }

        .post-card-image-container {
            @media (--menu-query) {
                margin-bottom: 0;
                padding-left: 20px;
                padding-left: 2rem;
                padding-right: 0;

                img {
                    border-radius: var(--border-radius);
                    width: 100%;
                }
            }
        }

        .post-card-content {
            .post-title {
                font-size: var(--s2);
                margin-bottom: 10px;
                margin-bottom: 1rem;
            }
        }
    }
}