/*--------------------------------------------------------------
# Global Styles
--------------------------------------------------------------*/
*,
:after,
:before {
    box-sizing: border-box;
	overflow-wrap: break-word;
}

*::selection {
	background: var(--selection-color);
	color: var(--selection-text-color);
}

/* Text meant only for screen readers. */
.hide-label label,
.screen-reader-text,
.assistive-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus,
.assistive-text:focus {
	background-color: #f1f1f1;
	border-radius: 4px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 16px 24px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

html {
	font-size: 62.5%; /* Base 10, please */
	overflow-x: hidden;
	position: relative;
	scroll-behavior: smooth;
}

body {
	font-size: 1.6rem; /* Default to 16px */
	overflow-x: hidden;
	position: relative;
}