.site-logo {
    display: block;
    border-radius: 0;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

.menu-toggle {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 10px;
    padding: 1rem;
}

.search-toggle {
    display: none;
    padding: 10px 12px;
    padding: 1rem 1.2rem;

    @media (--menu-query) {
        display: inline-block;
    }
}

.home-block {
    padding: 20px;
    padding: 2rem;
}